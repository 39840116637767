<template>
    <div class="card">
        <h3 class="card-title">Calendario de partes diarios</h3>
        <div class="w-100 d-flex center" style="flex-direction:column">
            <h5 class="mb-1">Seleccione un día: </h5>
            <v-calendar :attributes="calendarAttributes" @dayclick="dayClicked"></v-calendar>
        </div>
    </div>
</template>
<script>
    import { DateTime } from 'luxon'
    export default {
        name: 'CalendarioDePartesDiarios',
        data(){
            return{
                calendarAttributes: []
            }
        },
        mounted(){
            const today = DateTime.now()
            const calendarItem = {
                key: today.toFormat('ddLLyyyy'),
                highlight: true,
                dates: new Date(today.toISO()),
                customData: today.toFormat('ddLLyyyy'),
            }
            this.calendarAttributes.push(calendarItem)
        },
        methods:{
            dayClicked(day){
                console.log(day)
                const dateSelected = DateTime.fromFormat(day.id, 'yyyy-LL-dd')
                this.$router.push({name: 'CreateAndEditParteDiario', params: {date: dateSelected.toFormat('yyyyLLdd')}})
            }
        }
    }
</script>
<style>
.vc-container{
    width: 500px;
    max-width: 80vw!important;
    border-radius: 0;
    border: 1px solid var(--bg-color);
}
.vc-header{
    padding-bottom:10px!important;
    background-color: var(--bg-color);
    text-transform: capitalize;
}
</style>